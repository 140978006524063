import React from 'react';
import {Link} from 'gatsby';
import ReactWOW from 'react-wow';

//import StarIcon from '../../assets/images/star-icon.png'
// import HowItsWork from '../../assets/images/how-it-works.png'

import Shape1 from '../../assets/images/main-banner/locron-banner/Maximize.png'
import Shape2 from '../../assets/images/main-banner/locron-banner/Returns.png'
import Shape3 from '../../assets/images/main-banner/locron-banner/OnYour.png'
import Shape4 from '../../assets/images/main-banner/locron-banner/Investments.png'
import shape1 from '../../assets/images/shape/map-shape1.png'
import shape4 from '../../assets/images/shape/vector-shape4.png'
import shape5 from '../../assets/images/shape/vector-shape5.png'

import AniLink from "gatsby-plugin-transition-link/AniLink";

const HowItWork = () => {
    return (
        <section className="how-its-work-area">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-6 col-md-12 pt-100">
                        <div className="how-its-work-content pt-100">
                            <h3>Enhance Your Digital Presence</h3>
                            {/* <p>Strategy. Execution. Results.</p> */}
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h4>E-Commerce</h4>
                                    <p>Set Up and Optimize Your E-Commerce Operations</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h4>Digital Marketing</h4>
                                    <p>Increase Your Sales & Marketing Channels</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h4>Digital Transformation</h4>
                                    <p>Transform Through Purpose & Innovation</p>
                                </div>
                                <div className="single-item">
                                    
                                    <AniLink 
                                    paintDrip
                                    hex="#ff5d22"
                                    to="/capabilities/allservices" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Learn More <span></span>
                                    </AniLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                <div className="col-lg-6 col-md-12 ptb-10">

                    <ReactWOW animation='fadeInLeft'>
                    <div className="col-lg-12 col-md-12">
                        <div className="how-its-work-image">
                            {/* <img src={HowItsWork} alt="image" /> */}
                        </div>
                    </div>
                    </ReactWOW>

                    <ReactWOW delay='.00s' animation='fadeInDown'>
                    <div className="col-lg-12 col-md-12">
                        <div className="how-its-work-image">
                            <img src={Shape1} alt="image" />
                        </div>
                    </div>
                    </ReactWOW>
                    <ReactWOW delay='.5s' animation='fadeInDown'>
                    <div className="col-lg-12 col-md-12">
                        <div className="how-its-work-image">
                            <img src={Shape2} alt="image" />
                        </div>
                    </div>
                    </ReactWOW>
                    <ReactWOW delay='.75s' animation='fadeInDown'>
                    <div className="col-lg-12 col-md-12">
                        <div className="how-its-work-image">
                            <img src={Shape3} alt="image" />
                        </div>
                    </div>
                    </ReactWOW>
                    <ReactWOW delay='1.25s' animation='fadeInDown'>
                    <div className="col-lg-12 col-md-12">
                        <div className="how-its-work-image">
                            <img src={Shape4} alt="image" />
                        </div>
                    </div>
                    </ReactWOW>
                 <div className="map-shape1">
                   <img src={shape1} alt="banner" />
                 </div>
                </div>
            </div>


            </div>

        </section>
    )
}

export default HowItWork;