import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import howitsWork from '../../assets/images/whyus.png'

const WhyChooseUs = () => {
    return (
        <section className="how-its-work-area pt-70">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={starIcon} alt="choose" /> 
                                Why Choose Us
                            </span>
                            <h2>Cost-effective Digital Solutions</h2>
                            <p>Our experience with Fortune 500 companies and global outreach enable us to deliver reliable and effective solutions</p>

                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Wealth of Experience and Know-hows</h3>
                                    <p>We use agile operating models and latest devops frameworks to deliver superior value</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Dedicated Global Teams for Less</h3>
                                    <p>We can ensure high quality and cost-effective deliveries with our hybrid model of in-house and off-shore teams</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>24/7 Support</h3>
                                    <p>The global presence of our team members enables us to provide uninterrupted support across the time zones</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={howitsWork} alt="choose" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs